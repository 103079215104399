.import_word_doc_page {

}

.import_word_doc_page .dropzone {
  margin: 2em;
  padding: 1em;
  display: flex;
  flex: 1;
  border: 0.25em dashed #999999;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.import_word_doc_page .accept {
  background-color: #dceddd;
}

.import_word_doc_page .reject {
  border: 0.25em dashed #4D4D4D;
  background-color: #ffdede;
}

.import_word_doc_page .uploading {
  margin: 2em;
  padding: 1em;
  display: flex;
  flex: 1;
  border: 0.25em dashed #999999;
  justify-content: center;
  align-items: center;
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2em;
  padding: 2em;
}

.error > div {
  background-color: #fdd;
  border-radius: 0.5em;
  padding: 2em;
  margin-bottom: 4em;
  text-align: center;
  color: #4D4D4D;
}
